import React from "react";
import Routers from "./routers";
import { AuthProvider } from './hooks/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <AuthProvider>
        <Routers />
      </AuthProvider>
      <ToastContainer autoClose={3000} theme="colored" />
    </>
  );
}

export default App;