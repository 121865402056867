import React, { useCallback, useEffect, useState } from 'react';
import {
    AddContact,
    AddContactAlert,
    ModalAddContact,
    ShareProfile,
    Photo, Description, Name, Link, TextLink, RowRedes, Redes, RedesImage,
    ViewProfile, Back, VisibleText, TextModule, ViewText, ViewTextModule,
    ViewModal,
    ContainerText,
    ViewPix,
    TitlePix,
    TextPix,
    MessagePix,
    ButtonPix,
} from './styles';
import { MdClose, MdShare } from 'react-icons/md';
import vCardsJS from 'vcards-js';
import FileSaver from 'file-saver';
import { IoPersonAddSharp } from 'react-icons/io5';
import user from "./../images/user.png"
import { redesSociais } from './../Const';
import copy from 'copy-to-clipboard';
import Icons from '../Icons';
import { ReactComponent as Logo } from './../images/icone-pix.svg';

export default function Profile({ profileParam }) {

    const [showAddContactAlert, setShowAddContactAlert] = useState(false);
    const [showAddContactButton, setShowAddContactButton] = useState(false);
    const [activeModalAddContact, setActiveModalAddContact] = useState(false);
    const [profile, setProfile] = useState(profileParam);
    const [pix, setPix] = useState({
        pixKeys: []
    });
    const [visibleModalPix, setVisibleModalPix] = useState(false);
    const [emergency, setEmergency] = useState({
        emergencyContacts: []
    });
    const [visibleModalEmergency, setVisibleModalEmergency] = useState(false);


    const iphone = () => {
        if (typeof window === 'undefined' || typeof navigator === 'undefined') {
            return false;
        }

        return /iPhone/i.test(navigator.userAgent || navigator.vendor);
    };

    useEffect(() => {
        setProfile(profileParam)
        setTimeout(() => {
            if (profileParam.save_contact) {
                setShowAddContactAlert(true);
            }
        }, 2000);

        setTimeout(() => {
            if (profileParam.save_contact) {
            setShowAddContactAlert(false);
            setShowAddContactButton(true);
            }
        }, 7000);
    }, [profile]);

    const saveContact = useCallback(() => {
        const { name, sociais, photo, photo_url } = profile;
        let phone_number = ""
        let email = ""
        const vCard = vCardsJS();
        sociais.map((item) => {
            if (item.name === "Telefone" && !phone_number) {
                phone_number = item.url
            } if (item.name === "Email" && !email) {
                email = item.url
            }
        })
        vCard.firstName = name || '';
        vCard.cellPhone = phone_number || '';
        vCard.photo.embedFromString('');
        vCard.email = email || "";
        vCard.workPhone = phone_number || "";
        if (photo) {
            vCard.photo.attachFromUrl(photo_url, 'JPG');
        }
        vCard.url = `https://qrbio.me/${profile.nickname}`;

        const file = new Blob([`${vCard.getFormattedString()}`], {
            type: 'text/vcard;charset=utf-8',
        });

        FileSaver.saveAs(file, 'temp.vcf');
        setActiveModalAddContact(false);
    }, [profile]);

    const closeModalAddContact = event => {
        if (event.target === event.currentTarget) {
            setActiveModalAddContact(false);
        }
    };

    function openAddContact() {
        if (!iphone()) {
            return setActiveModalAddContact(true);
        } else {
            saveContact();
        }
    }

    document.title = profile.name + " | QR Bio"

    return (
        <ViewProfile>
            {profile.view_profile ? (
                <Photo src={profile.photo ? profile.photo_url : user} />
            ) : (
                <div style={{height: 80}}></div>
            )}
            {profile.name_visible && (
                <Name style={{ color: profile.font_color ? profile.font_color : "#fff", fontFamily: profile.font ? profile.font : "Montserrat-Bold", marginTop: profile.view_profile ? 5 : 80}}>
                    {profile.name}
                </Name>
            )}
            {profile.description_visible && (
                <Description style={{ color: profile.font_color ? profile.font_color : "#fff", fontFamily: profile.font ? profile.font : "Montserrat-Regular" }}>
                    {profile.description}
                </Description>
            )}
            {
                profile.modules.map((item, index) => {
                    if (item.type == "pix") {
                        return (
                            <Link style={{marginTop: index ? 0 : 20}} onClick={() => {
                                setPix(item)
                                setVisibleModalPix(true)
                            }}>
                                <Back style={{ backgroundColor: profile.button_color ? profile.button_color : "#000", opacity: profile.button_opacity / 100 }} />
                                <Logo style={{ position: "absolute", left: 10, fill: profile.font_button_color ? profile.font_button_color : "#fff" }} />
                                <TextLink style={{ color: profile.font_button_color ? profile.font_button_color : "#fff", fontFamily: profile.font ? profile.font : "Montserrat-Regular" }}>
                                    {item.name}
                                </TextLink>
                                <VisibleText style={{ color: "transparent" }}>
                                    {item.name}
                                </VisibleText>
                            </Link>
                        )
                    }
                    if (item.type == "emergency") {
                        return (
                            <Link style={{marginTop: index ? 0 : 20}} onClick={() => {
                                setEmergency(item)
                                setVisibleModalEmergency(true)
                            }}>
                                <Back style={{ backgroundColor: profile.button_color ? profile.button_color : "#000", opacity: profile.button_opacity / 100 }} />
                                <Icons icon_name={"heart"} color={profile.font_button_color ? profile.font_button_color : "#fff"} />
                                <TextLink style={{ color: profile.font_button_color ? profile.font_button_color : "#fff", fontFamily: profile.font ? profile.font : "Montserrat-Regular" }}>
                                    {item.name}
                                </TextLink>
                                <VisibleText style={{ color: "transparent" }}>
                                    {item.name}
                                </VisibleText>
                            </Link>
                        )
                    }
                    if (item.type == "link") {
                        return (
                            <Link style={{marginTop: index ? 0 : 20}} onClick={() => {
                                window.location.assign(item.url);
                            }}>
                                <Back style={{ backgroundColor: profile.button_color ? profile.button_color : "#000", opacity: profile.button_opacity / 100 }} />
                                <Icons icon_name={item.icon_name} color={profile.font_button_color ? profile.font_button_color : "#fff"} />
                                <TextLink style={{ color: profile.font_button_color ? profile.font_button_color : "#fff", fontFamily: profile.font ? profile.font : "Montserrat-Regular" }}>
                                    {item.name}
                                </TextLink>
                                <VisibleText style={{ color: "transparent" }}>
                                    {item.name}
                                </VisibleText>
                            </Link>
                        )
                    }
                    if (item.type == "text") {
                        return (
                            <ContainerText style={{marginTop: index ? 0 : 20}}>
                                <ViewText onClick={() => {
                                    let modules = profile.modules
                                    let profileC = { ...profile }
                                    modules[index].open = !modules[index].open
                                    profileC.modules = [...modules]
                                    setProfile(profileC)
                                }}>
                                    <Back style={{ borderBottomRightRadius: item.open ? 0 : 8, borderBottomLeftRadius: item.open ? 0 : 8, backgroundColor: profile.button_color ? profile.button_color : "#000", opacity: profile.button_opacity / 100 }} />

                                    <Icons icon_name={item.icon_name} color={profile.font_button_color ? profile.font_button_color : "#fff"} />
                                    <TextLink style={{ borderBottomRightRadius: item.open ? 0 : 8, borderBottomLeftRadius: item.open ? 0 : 8, color: profile.font_button_color ? profile.font_button_color : "#fff", fontFamily: profile.font ? profile.font : "Montserrat-Regular" }}>
                                        {item.name}
                                    </TextLink>
                                    <VisibleText style={{ color: "transparent" }}>
                                        {item.name}
                                    </VisibleText>
                                </ViewText>
                                {item.open && (
                                    <ViewTextModule style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
                                        <TextModule style={{
                                            fontFamily: profile.font ? profile.font : "Montserrat-Medium",
                                            textAlign: item.alignment == "left" ? "left" : "center",
                                        }}>
                                            {item.text}
                                        </TextModule>
                                    </ViewTextModule>
                                )}
                            </ContainerText>
                        )
                    }
                })
            }
            <RowRedes>
                {
                    profile.sociais.map((item) => {
                        return (
                            <Redes onClick={() => {
                                if (item.name == "Instagram") {
                                   window.location.assign(redesSociais[item.name].url + item.url.replaceAll("@",""));
                                } else{
                                    if (item.name == "Whatsapp") {
                                        window.location.assign(redesSociais[item.name].url + item.url.replace(/\D/g, ''));
                                    } else {
                                        window.location.assign(redesSociais[item.name].url + item.url); 
                                    }
                                }  
                            }}>
                                <RedesImage src={redesSociais[item.name].icon} />
                            </Redes>
                        )
                    })
                }
            </RowRedes>
            <AddContactAlert
                showAddContactAlert={showAddContactAlert}
                onClick={openAddContact}
            >
                <IoPersonAddSharp color="#000" size={26} />
                <p>
                    Toque aqui para adicionar
                    <strong>{` ${profile.name} `}</strong>à sua agenda
                </p>
            </AddContactAlert>

            {activeModalAddContact && (
                <ModalAddContact onClick={event => closeModalAddContact(event)}>
                    <div>
                        <p>
                            Para adicionar o contato, abra o arquivo baixado e toque em
                            Importar contato
                        </p>

                        <button type="button" onClick={saveContact}>
                            Adicionar contato
                        </button>

                        <span onClick={() => setActiveModalAddContact(false)}>
                            <MdClose size={18} color="#000" />
                        </span>
                    </div>
                </ModalAddContact>
            )}
            {
                navigator.canShare && (
                    <ShareProfile
                        type="button"
                        onClick={() => {
                            navigator.share({
                                url: `https://qrbio.me/${profile.nickname}`,
                            });
                        }}
                    >
                        <Back style={{ backgroundColor: profile.button_color ? profile.button_color : "#000", opacity: profile.button_opacity / 100, borderRadius: 40 }} />
                        <MdShare color={profile.font_button_color ? profile.font_button_color : "#fff"} 
                            style={{ position: "absolute" }} />
                    </ShareProfile>
                )
            }


            {showAddContactButton && (
                <AddContact type="button" onClick={openAddContact}>
                    <Back style={{ backgroundColor: profile.button_color ? profile.button_color : "#000", opacity: profile.button_opacity / 100, borderRadius: 40 }} />
                    <IoPersonAddSharp
                        color={profile.font_button_color ? profile.font_button_color : "#fff"}
                        style={{
                            position: "absolute"
                        }}
                    />
                </AddContact>
            )}

            {visibleModalPix && (
                <ViewModal>
                    <div>
                        {
                            pix.pixKeys.map((item) => {
                                if (item.visible) {
                                    return (
                                        <ViewPix kkey={item.id} style={{ width: "100%", marginBottom: 12, paddingBottom: 50, position: "relative" }}>
                                            <TitlePix style={{ width: "100%", padding: 0, marginBottom: 10, justifyContent: "flex-start" }}>
                                                <p>{item.name}</p>
                                                {
                                                    item.type && (
                                                        <span>{item.type}</span>
                                                    )
                                                }
                                            </TitlePix>
                                            <TextPix>
                                                {item.key}
                                            </TextPix>
                                            <ButtonPix onClick={() => {
                                                copy(item.key);
                                                alert("Chave pix copiada")
                                            }}>
                                                Copiar Chave
                                            </ButtonPix>
                                        </ViewPix>
                                    )
                                }

                            })
                        }
                        {pix.visible_message && (
                            <MessagePix>
                                {pix.message}
                            </MessagePix>
                        )}
                        <p onClick={() => {
                            setVisibleModalPix(false)
                        }} style={{ fontSize: 14, fontFamily: "Montserrat-Medium", width: "95%", textAlign: "right", marginTop: 5, cursor: "pointer" }}>FECHAR{" "}X</p>
                    </div>
                </ViewModal>
            )
            }

            {visibleModalEmergency && (
                <ViewModal>
                    <div>
                        <h3 style={{ marginBottom: 15 }}>Contatos de Emergência</h3>
                        {
                            emergency.emergencyContacts.map((item) => {
                                if (item.visible) {
                                    return (
                                        <ViewPix key={item.id} style={{ width: "100%", marginBottom: 12, paddingBottom: 12, position: "relative" }}>
                                            <TitlePix style={{ padding: 0, marginBottom: 10, width: "100%", justifyContent: "flex-start" }}>
                                                <p>{item.name}</p>
                                            </TitlePix>
                                            <div style={{ padding: 0, margin: 0, width: "100%", justifyContent: "space-between" }}>
                                                <p>{item.kinship}</p>
                                                <div style={{ width: "auto", margin: 0, padding: 0, justifyContent: "flex-end" }}>
                                                    <Redes style={{ width: 30, height: 30 }} onClick={() => {
                                                        window.location.assign(redesSociais["Whatsapp"].url + item.contact + (emergency.message ? `&text=${emergency.message}` : ""))
                                                    }}>
                                                        <RedesImage style={{ width: 30, height: 30 }} src={redesSociais["Whatsapp"].icon} />
                                                    </Redes>
                                                    <Redes style={{ width: 30, height: 30, margin: 0 }} onClick={() => {
                                                        window.location.assign(redesSociais["Telefone"].url + item.contact)
                                                    }}>
                                                        <RedesImage style={{ width: 30, height: 30 }} src={redesSociais["Telefone"].icon} />
                                                    </Redes>
                                                </div>
                                            </div>

                                        </ViewPix>
                                    )
                                }

                            })
                        }
                        {
                            !!emergency.type_blood && (
                                <ViewPix style={{ width: "100%", marginBottom: 12, paddingBottom: 12, position: "relative" }}>
                                    <MessagePix style={{marginTop: 0, marginBottom: 0, fontSize: 16,fontFamily: "Montserrat-SemiBold", color: "#B60000"}}>
                                    Tipo Sanguíneo: {emergency.type_blood}
                                    </MessagePix>
                                </ViewPix>
                            )
                        }
                        {
                            !!emergency.observation && (
                                <ViewPix style={{ width: "100%", marginBottom: 12, paddingBottom: 12, position: "relative" }}>
                                    <MessagePix style={{ marginTop: 0, marginBottom: 0, fontSize: 14, fontFamily: "Montserrat-Medium", textAlign: "left", color: "#B60000" }}>
                                        Observações: {emergency.observation}
                                    </MessagePix>
                                </ViewPix>
                            )
                        }
                        <p onClick={() => {
                            setVisibleModalEmergency(false)
                        }} style={{ fontSize: 14, fontFamily: "Montserrat-Medium", width: "95%", textAlign: "right", marginTop: 5, cursor: "pointer" }}>FECHAR{" "}X</p>
                    </div>
                </ViewModal>
            )
            }
        </ViewProfile >
    )
} 