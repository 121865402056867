import styled from "styled-components";


export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 24px;
  align-items: center;
  border-radius: 8px;
  margin: 15px 0 0 0;
 color: #fff;
  height: 26px;
  width: 323px;
  position: fixed;
  bottom: 10px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 0 11px;
  border-radius: 8px;
  p{
    margin: 0;
    font-size: 10px;
    font-weight: 600;
    margin-right: 4px;
    font-family: "Montserrat-Medium";
  }
  
  img{
    object-fit: contain;
    width: 40px;
    height: 15px;
  }
`;

export const Container = styled.div`
  width: 100vw;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  justify-content: space-between;

  li{
    background: transparent !important;
  }
  ul{
    max-width: 100vw;
  }

`;

export const Partner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  font-family: "Montserrat-Regular";
  margin: 20px 0 40px 0;
  z-index: 9999;
  img{
    width: 250px;
    max-height: 120px;
    object-fit: contain;
    border-radius: 15px;
    margin-top: 10px;
  }
  span {
    color: #000;
    font-weight: 600;
    width: 260px;
    margin: 15px 0 5px 0;
    font-size: 15px;
    text-align: center;
    line-height: 20.85px;
  }
`;