import React from 'react';
import {
    MdShoppingCart,
    MdLink,
    MdPlace,
    MdPhone,
    MdMessage,
    MdHelp,
    MdInfo,
    MdLanguage,
    MdSmartphone,
    MdAccountBalance,
    MdBookmark,
    MdDateRange,
    MdCameraAlt,
    MdCheckCircle,
    MdFileDownload,
    MdQuestionAnswer,
    MdLightbulbOutline,
    MdLock,
    MdSecurity,
    MdShare,
    MdStar,
    MdStore,
    MdTimer,
    MdWidgets,
    MdHome,
    MdArrowBack,
    MdArrowDownward,
    MdArrowForward,
    MdArrowUpward,
    MdLocalDining,
    MdDirectionsBus,
    MdDirectionsCar,
    MdDirectionsBike,
    MdDirectionsRun,
    MdLocalBar,
    MdLocalDrink,
    MdCake,
    MdAccountCircle,
    MdPerson
} from 'react-icons/md';
import {
    FaAppleAlt,
    FaBriefcaseMedical,
    FaBuffer,
    FaBuilding,
    FaChartBar,
    FaFacebook,
    FaGift,
    FaHeart,
    FaInstagram,
    FaLinkedin,
    FaPercentage,
    FaQrcode,
    FaTag,
    FaTwitter,
    FaWhatsapp,
    FaYoutube,
    FaMotorcycle
} from 'react-icons/fa';
import {
    BsFill0SquareFill,
    BsFill1SquareFill,
    BsFill2SquareFill,
    BsFill3SquareFill,
    BsFill4SquareFill,
    BsFill5SquareFill,
    BsFill6SquareFill,
    BsFill7SquareFill,
    BsFill8SquareFill,
    BsFill9SquareFill
} from 'react-icons/bs';

import {
    Container,
} from './styles';

const Icons = ({
    icon_name,
    color,
}) => {
    function getIcon(iconName) {
        switch (iconName) {
            case 'whatsapp':
                return <FaWhatsapp color={color} />;
            case 'instagram':
                return <FaInstagram color={color} />;
            case 'linkedin':
                return <FaLinkedin color={color} />;
            case 'facebook':
                return <FaFacebook color={color} />;
            case 'twitter':
                return <FaTwitter color={color} />;
            case 'youtube':
                return <FaYoutube color={color} />;
            case 'map-marker':
                return <MdPlace color={color} />;
            case 'phone':
                return <MdPhone color={color} />;
            case 'message-bulleted':
                return <MdMessage color={color} />;
            case 'help-circle':
                return <MdHelp color={color} />;
            case 'information':
                return <MdInfo color={color} />;
            case 'web':
                return <MdLanguage color={color} />;
            case 'cellphone':
                return <MdSmartphone color={color} />;
            case 'bank':
                return <MdAccountBalance color={color} />;
            case 'bookmark':
                return <MdBookmark color={color} />;
            case 'buffer':
                return <FaBuffer color={color} />;
            case 'calendar-month':
                return <MdDateRange color={color} />;
            case 'camera':
                return <MdCameraAlt color={color} />;
            case 'chart-bar':
                return <FaChartBar color={color} />;
            case 'check-circle':
                return <MdCheckCircle color={color} />;
            case 'download':
                return <MdFileDownload color={color} />;
            case 'gift':
                return <FaGift color={color} />;
            case 'forum':
                return <MdQuestionAnswer color={color} />;
            case 'heart':
                return <FaHeart color={color} />;
            case 'lightbulb-on':
                return <MdLightbulbOutline color={color} />;
            case 'lock':
                return <MdLock color={color} />;
            case 'qrcode':
                return <FaQrcode color={color} />;
            case 'sale':
                return <FaPercentage color={color} />;
            case 'security':
                return <MdSecurity color={color} />;
            case 'share-variant':
                return <MdShare color={color} />;
            case 'star':
                return <MdStar color={color} />;
            case 'store':
                return <MdStore color={color} />;
            case 'tag':
                return <FaTag color={color} />;
            case 'timer':
                return <MdTimer color={color} />;
            case 'widgets':
                return <MdWidgets color={color} />;
            case 'home':
                return <MdHome color={color} />;
            case 'arrow-left':
                return <MdArrowBack color={color} />;
            case 'arrow-down':
                return <MdArrowDownward color={color} />;
            case 'account':
                return <MdPerson color={color} />;
            case 'account-circle':
                return <MdAccountCircle color={color} />;
            case 'arrow-right':
                return <MdArrowForward color={color} />;
            case 'arrow-up':
                return <MdArrowUpward color={color} />;
            case 'food':
                return <MdLocalDining color={color} />;
            case 'food-fork-drink':
                return <MdLocalDining color={color} />;
            case 'bus':
                return <MdDirectionsBus color={color} />;
            case 'car':
                return <MdDirectionsCar color={color} />;
            case 'cart':
                return <MdShoppingCart color={color} />;
            case 'bike':
                return <MdDirectionsBike color={color} />;
            case 'run':
                return <MdDirectionsRun color={color} />;
            case 'motorbike':
                return <FaMotorcycle color={color} />;
            case 'beer':
                return <MdLocalBar color={color} />;
            case 'cupcake':
                return <MdCake color={color} />;
            case 'cup':
                return <MdLocalDrink color={color} />;
            case 'medical-bag':
                return <FaBriefcaseMedical color={color} />;
            case 'office-building':
                return <FaBuilding color={color} />;
            case 'food-apple':
                return <FaAppleAlt color={color} />;
            case 'numeric-0-box':
                return <BsFill0SquareFill color={color} />;
            case 'numeric-1-box':
                return <BsFill1SquareFill color={color} />;
            case 'numeric-2-box':
                return <BsFill2SquareFill color={color} />;
            case 'numeric-3-box':
                return <BsFill3SquareFill color={color} />;
            case 'numeric-4-box':
                return <BsFill4SquareFill color={color} />;
            case 'numeric-5-box':
                return <BsFill5SquareFill color={color} />;
            case 'numeric-6-box':
                return <BsFill6SquareFill color={color} />;
            case 'numeric-7-box':
                return <BsFill7SquareFill color={color} />;
            case 'numeric-8-box':
                return <BsFill8SquareFill color={color} />;
            case 'numeric-9-box':
                return <BsFill9SquareFill color={color} />;
            default:
                return <MdLink color={color} />;
        }
    }

    return (
        <Container>
            {icon_name && getIcon(icon_name)}
        </Container>
    )
}

export default Icons