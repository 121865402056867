import React from "react";
import { Routes, Route } from "react-router-dom";

import Redirect from "../Redirect";
import Home from "../Home";

const Routers = () => {

  return (
    <Routes>
      <Route exact path="/" element={<Redirect />} />
      <Route exact path="/:id" element={<Home />} />
    </Routes>
  );
};

export default Routers;