import React from 'react';
import { Photo, Message, Download, Loja, Container } from './styles';
import { useParams } from 'react-router-dom';
import attention from "./../images/attention.png"
import playstore from "./../images/playstore.png"
import appstore from "./../images/appstore.png"

export default function Error({ error }) {

    const { id } = useParams()


    return (
        <Container>
            <Photo src={attention} />
            {
                error == "Tag" ? (
                    <Message>Esta tag não tem nenhum perfil vinculado. Para vincular um perfil, baixe o nosso aplicativo e crie uma conta</Message>
                )
                    : error == "Perfil" ?
                        (
                            <Message>O perfil que você está tentando acessar é inválido.</Message>
                        )
                        : error == "Limite" ?
                            (
                                <Message>Esse perfil atingiu o limite de leituras mensal</Message>
                            )
                            :
                            (
                                <Message>Erro Interno, verifique sua conexão ou entre em contato conosco</Message>
                            )
            }
            {
                error == "Tag" && (
                    <Download>
                        <Loja onClick={() => {
                            window.location.assign("https://apps.apple.com/br/app/qr-bio/id6444109866");
                        }} src={appstore} />
                        <Loja onClick={() => {
                            window.location.assign("https://play.google.com/store/apps/details?id=solucoesdaweb.qrbio");
                        }} src={playstore} />
                    </Download>
                )
            }
        </Container >
    )
} 