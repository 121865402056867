import photoUser from "./images/user.png";
import iconTelegram from "./images/iconTelegram.png";
import iconSite from "./images/iconSite.png";
import iconMaps from "./images/iconMaps.png";
import iconLinkedin from "./images/iconLinkedin.png";
import iconTwitter from "./images/iconTwitter.png";
import iconWhatsapp from "./images/iconWhatsapp.png";
import iconYoutube from "./images/iconYoutube.png";
import iconFacebook from "./images/iconFacebook.png";
import iconInstagram from "./images/iconInstagram.png";
import iconBehance from "./images/iconBehance.png";
import iconEmail from "./images/iconEmail.png";
import iconGoogleplay from "./images/iconGoogleplay.png";
import iconAppstore from "./images/iconAppstore.png";
import iconSpotify from "./images/iconSpotify.png";
import iconTikTok from "./images/iconTikTok.png";
import iconTelefone from "./images/iconTelefone.png";
import iconQrcare from "./images/iconQrcare.png";


export const redesSociais = {
    "": {
        icon: photoUser,
        url: "",
        name: null
    },
    null: {
        icon: photoUser,
        url: "",
        name: null
    },
    "QRCare": {
        icon: iconQrcare,
        url: "",
        name: "QRCare"
    },
    "Telegram": {
        icon: iconTelegram,
        url: "",
        name: "Telegram"
    },
    "Instagram": {
        icon: iconInstagram,
        url: "https://instagram.com/",
        name: "Instagram"
    },
    "Googleplay": {
        icon: iconGoogleplay,
        url: "",
        name: "Googleplay"
    },
    "Facebook": {
        icon: iconFacebook,
        url: "",
        name: "Facebook"
    },
    "Youtube": {
        icon: iconYoutube,
        url: "",
        name: "Youtube"
    },
    "Site": {
        icon: iconSite,
        url: "",
        name: "Site"
    },
    "Maps": {
        icon: iconMaps,
        url: "",
        name: "Maps"
    },
    "Twitter": {
        icon: iconTwitter,
        url: "https://twitter.com/",
        name: "Twitter"
    },
    "Whatsapp": {
        icon: iconWhatsapp,
        url: "https://api.whatsapp.com/send?phone=",
        name: "Whatsapp"
    },
    "Linkedin": {
        icon: iconLinkedin,
        url: "",
        name: "Linkedin"
    },
    "Email": {
        icon: iconEmail,
        url: "mailto:",
        name: "Email"
    },
    "Behance": {
        icon: iconBehance,
        url: "",
        name: "Behance"
    },
    "Appstore": {
        icon: iconAppstore,
        url: "",
        name: "Appstore"
    },
    "TikTok": {
        icon: iconTikTok,
        url: "",
        name: "TikTok"
    },
    "Spotify": {
        icon: iconSpotify,
        url: "",
        name: "Spotify"
    },
    "Telefone": {
        icon: iconTelefone,
        url: "tel:",
        name: "Telefone"
    },
}