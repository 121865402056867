import styled, { css } from 'styled-components';

export const Photo = styled.img`
  border-radius: 130px;
  height: 130px;
  width: 130px;
  margin: 40px 0 15px 0;

  @media (min-width: 600px) {
  border-radius: 160px;
  height: 160px;
  width: 160px;
  margin: 40px 0 20px 0;
  }
`;


export const ContainerText = styled.div`
  justify-content: center;
  align-items: center;
  width: 85%;
  margin-bottom: 15px;
  background-color: transparent;
`;

export const ViewText = styled.button`
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  padding: 0px;
  background-color: transparent;
  position: relative;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  
  @media (min-width: 600px) {
    font-size: 25px;
  }
`;

export const ViewTextModule = styled.div`
  width: 100%;
  display: flex;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background-color: #fff;
`;

export const TextModule = styled.p`
  font-size: 16px;
  color: #000;
  font-family: 'Montserrat-Medium';
  width: 80%;
  text-align: center;
  white-space: pre-line;
  word-break: break-word;
`;

export const Name = styled.p`
  font-size: 18px;
  width: 80%;
  margin-bottom: 5px;
  text-align: center;
  @media (min-width: 600px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
`;

export const Description = styled.p`
  font-size: 14px;
  width: 75%;
  margin-bottom: 10px;
  text-align: center;
  white-space: pre-line;
  word-break: break-word;

  @media (min-width: 600px) {
    font-size: 17px;
    margin: 0px 0 15px 0;
  }
`;

export const Link = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  padding: 0px;
  background-color: transparent;
  position: relative;
  border-radius: 8px;
  margin-bottom: 15px;
  font-size: 20px;
  cursor: pointer;

  svg{
    width: 20px;
    height: 20px;
  }

  @media (min-width: 600px) {
    font-size: 25px;
  svg{
    width: 25px;
    height: 25px;
  }
  }
`;

export const TextLink = styled.p`
  font-size: 16px;
  line-height: 16px;
  margin: 15px 45px;
  text-align: center;
  width: calc(100% - 90px);
  position: absolute;
  top: 0;

  @media (min-width: 600px) {
    margin: 20px 45px;
    font-size: 19px;
  }
`;

export const VisibleText = styled.p`
  font-size: 16px;
  line-height: 16px;
  margin: 15px 45px;
  text-align: center;
  width: calc(100% - 90px);

  @media (min-width: 600px) {
    margin: 20px 45px;
  }
`;

export const Back = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 8px;
  background-color: #000;
  cursor: pointer;
`;


export const RowRedes = styled.div`
  display: flex;
  width: 90%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const ViewProfile = styled.div`
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const Redes = styled.button`
  height: 45px;
  width: 45px;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  border-radius: 45px;
  border: none;
  margin: 7px;
`;

export const RedesImage = styled.img`
  height: 45px;
  width: 45px;
  border-radius: 45px;
`;

export const Fundo = styled.img`
  height: calc(100% + 50px);
  width: 100%;
  position: fixed;
  top: 0;
`;


export const Partner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: "Montserrat-Regular";
  margin: 10px 0 50px 0;

  img{
    width: 250px;
    max-height: 120px;
    object-fit: contain;
    border-radius: 15px;
    margin-top: 10px;
  }
  span {
    color: #000;
    font-weight: 600;
    width: 260px;
    margin: 15px 0 5px 0;
    font-size: 15px;
    text-align: center;
    line-height: 20.85px;
  }
`;

export const AddContactAlert = styled.div`
  display: ${props => (props.showAddContactAlert ? 'flex' : 'none')};
  justify-content: space-around;
  position: fixed;
  padding: 13px;
  background: #fff;
  border-radius: 20px;
  width: 338px;
  left: 0;
  right: 0;
  font-family: "Montserrat-Regular";
  margin-left: auto;
  cursor: pointer;
  margin-right: auto;
  box-shadow: 0px 0px 10px #666a;
  z-index: ${props => (props.showAddContactAlert ? 999 : 0)};
  bottom: 10px;
  opacity: ${props => (props.showAddContactAlert ? 1 : 0)};
  @keyframes addContactAlertanimationIn {
    0% {
      opacity: 0;
      bottom: -42.5px;
    }
    20% {
      opacity: 0.5;
      bottom: -25px;
    }
    80% {
      opacity: 0.7;
      bottom: -7.5px;
    }
    100% {
      opacity: 1;
      bottom: 10px;
    }
  }
  @keyframes addContactAlertanimationOut {
    0% {
      opacity: 1;
      bottom: 10px;
    }
    20% {
      opacity: 0.7;
      bottom: -7.5px;
    }
    80% {
      opacity: 0.5;
      bottom: -25px;
    }
    100% {
      opacity: 0;
      bottom: -42.5px;
      display: none;
    }
  }
  ${({ showAddContactAlert }) =>
    showAddContactAlert &&
    css`
      animation: addContactAlertanimationIn 0.5s;
    `}
  ${({ showAddContactAlert }) =>
    !showAddContactAlert &&
    css`
      animation: addContactAlertanimationOut 0.2s;
    `}
  p {
    color: #000;
    font-weight: 600;
    width: 260px;
    font-size: 13px;
    text-align: center;
    line-height: 15.85px;
  }
  strong {
    font-family: "Montserrat-SemiBold";
  }
`;

export const ModalAddContact = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 9999;
  display: flex;
  font-family: "Montserrat-Regular";
  align-items: center;
  justify-content: center;
  div {
    width: 80%;
    max-width: 700px;
    margin: 0 auto;
    background: #d9d9d9;
    color: #363636;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    p {
      margin-top: 10px;
      font-size: 16px;
      text-align: center;
      font-weight: 500;
      line-height: 22px;
    }
    button {
      background: #61c384;
      color: #000;
      font-family: "Montserrat-Medium";
      font-size: 13px;
      border-radius: 8px;
      padding: 0 20px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      margin-top: 10px;
    }
    span {
      padding: 7px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }
`;


export const ShareProfile = styled.button`
  position: absolute;
  background: none;
  border: none;
  top: 15px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  font-size: 20px;
  svg{
    margin-left: -2px;
  }
  
  @media (min-width: 600px) {
    font-size: 28px;
    svg{
      margin-left: -3px;
    }
    width: 55px;
    height: 55px;
  }
`;

export const AddContact = styled.button`
  position: absolute;
  background: none;
  border: none;
  top: 15px;
  left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 15px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  font-size: 20px;
  svg{
    margin-left: -2px;
  }
  
  @media (min-width: 600px) {
    font-size: 28px;
    svg{
      margin-left: -3px;
    }
    width: 55px;
    height: 55px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 0.5s ease-in-out;
`;


export const ViewModal = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 9999;
  display: flex;
  font-family: "Montserrat-Regular";
  align-items: center;
  justify-content: center;

  div{
  display: flex;
  font-family: "Montserrat-Regular";
  align-items: center;
  border-radius: 15px;
  width: 80%;
  max-width: 700px;
  margin: 0px auto;
  padding: 20px 15px 15px 15px;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #FFF;
  justify-content: center;
  }
`;


export const ViewPix = styled.div`
  border-radius: 10px;
  width: 100%;
  background-color: #FFF;
  padding: 10px 10px 50px 10px;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
`;

export const TitlePix = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px;
  margin: 0px 0 10px 12px;
    
  p{
    width: 100%;
    font-size: 18px;
    color: #363636;
    font-family: 'Montserrat-SemiBold';
    text-align: left;
    margin-right: 10px;
  }
  span{
    background-color: #363636;
    padding: 2px 8px;
    font-size: 11px;
    border-radius: 5px;
    color: #fff;
    font-family: 'Montserrat-SemiBold';
    text-align: left;
  }
  
`;

export const TextPix = styled.span`
  font-size: 15px;
  background: #f0f0f0;
  padding: 4px 8px;
  margin-bottom: 12px;
  border-radius: 5px;
  color: #363636;
  font-family: 'Montserrat-Medium';
  width: 100%;
  text-align: left;
`;

export const MessagePix = styled.p`
  font-size: 14px;
  color: #363636;
  font-family: 'Montserrat-Medium';
  width: 95%;
  margin: 12px 0 8px 0;
  text-align: center;
`;


export const ButtonPix = styled.button`
  flex-direction: row;
  padding: 2px 10px;
  height: 30px;
  align-items: center;
  border-radius: 12px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: #62DE9B;
  font-size: 12px;
  color: #363636;
  font-family: 'Montserrat-SemiBold';
  text-align: center;
`;